
.tradesItem{
    width: 100%;
    height: 64px;
    padding-left: 16px;
    padding-right: 16px;
}

.tradesItem .content{
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(255,255,255, 0.05);
}

.tradesItem .row1{
    position: relative;
    margin-top: 12px;
    width: 100%;
    float: left;
}

.tradesItem .row2{
    margin-top: 6px;
    width: 100%;
    float: left;
}

.tradesItem .nr{
    position: absolute;
    font-size: 16px;
    color: #FFFFFF;
}

.tradesItem .symbol{
    float: left;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 20px;
}

.tradesItem .side{
    position: relative;
    float: left;
    top: -2px;
    margin-left: 16px;

    background: #2EBC85;
    border-radius: 2px;
    width: 60px;
    height: 20px;

    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
}

.tradesItem .price{
    float: left;
    margin-left: 16px;
    font-size: 14px;
    color: #FFFFFF;
}

.tradesItem .amount{
    float: right;
    font-size: 16px;
    color: #FFFFFF;
}

.tradesItem .date{
    float: left;
    opacity: 0.5;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 20px;
}

.tradesItem .amount_usd{
    float: right;
    opacity: 0.5;
    font-size: 14px;
    color: #FFFFFF;
}


